<template>
  <div class="item" ref="scroll-item" :value="source" @click="onClick(source)" :class="computeClass">
    <div v-if="source.countryFlag" class="country-flag">
      <i :class="['gigflag', `gigflag-${source.countryFlag.toLowerCase()}`]"></i>
    </div>

    <div class="id text-truncate">
      {{ prettifiedLabel }}
    </div>

    <div v-if="source.pages_count" class="pages-count">({{ source.pages_count }})</div>
  </div>
</template>

<script>
import mixins from '../../../mixins/ChildToParent'

export default {
  name: 'scroll-item',
  mixins: [mixins],
  inject: ['onChangeItem'],
  props: {
    source: {
      type: [Object, String, Number],
      default: () => null,
    },
    dataFromParent: { type: [String, Number, null], default: null },
    trackBy: { type: String, default: 'id' },
    pointer: { type: [Number, String], default: 0 },
    optionLabelKey: { type: String, default: 'label' },
    extraOptionLabelKey: { type: String, default: null },
    removeActiveText: { type: Boolean, default: false },
  },
  computed: {
    computeClass() {
      const val =
        typeof this.source === 'object' && this.source !== null ? this.source[this.trackBy] : this.source

      return {
        active: this.dataFromParent === val,
        'active-item': this.pointer === val && this.dataFromParent !== val && !this.removeActiveText,
        'active-item-remove': this.pointer === val && this.dataFromParent === val && !this.removeActiveText,
      }
    },
    prettifiedLabel() {
      let label = ''

      if (typeof this.source === 'object' && this.source !== null) {
        // Check if optionLabelKey is present on source
        if (this.optionLabelKey in this.source) {
          const prefix =
            this.extraOptionLabelKey && this.source[this.extraOptionLabelKey]
              ? this.source[this.extraOptionLabelKey] + ' > '
              : ''
          label = prefix + this.source[this.optionLabelKey]
        } else if ('name' in this.source && this.source.name) {
          // Fallback to source.name if optionLabelKey isn't found
          label = this.source.name
        } else {
          // If neither optionLabelKey nor name is available, stringify source
          label = String(this.source)
        }
      } else {
        // If source is a string or number, use it directly
        label = String(this.source)
      }

      return this.prettifyLabels(label)
    },
  },
  methods: {
    prettifyLabels(label) {
      return this.$prettyLabels(label)
    },
    onClick(source) {
      this.onChangeItem(source)
    },
  },
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 1em;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f6f6f6;
    color: #17182f;
  }
}

.active,
.active-item {
  background-color: #f6f6fb !important;
  color: #17182f !important;
}

.active-item::after,
.active-item-remove::after {
  position: absolute;
  right: 10px;
}

.active-item::after {
  content: 'Press enter to select';
}

.active-item-remove::after {
  content: 'Press enter to remove';
}

.country-flag {
  margin-top: 4px;
  i {
    margin-right: 4px;
  }
}
.pages-count {
  font-size: 0.85em;
  color: #999;
}
</style>
