<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex flex-wrap justify-content-between">
            <h3 class="flex-grow-1">General information</h3>
            <div
              v-if="confluenceId"
              @click="openModalInformation()"
              class="btn btn-outline-info btn-md popover-trigger mr-2"
            >
              <i class="uil uil-info-circle"></i>Help
            </div>
            <div @click="viewActivityLogs()" class="btn btn-outline-info btn-md popover-trigger">
              <i class="uil uil-history"></i>History
            </div>
          </div>
          <div class="card-body">
            <div class="multi-column-form">
              <div class="row">
                <div class="col">
                  <base-input label="Title (H1)" v-model="page.title" required />
                </div>
                <div class="col" data-tooltip="Page path can be changed in the advanced tab">
                  <base-input label="Path" v-model="page.path" read-only="true" disabled="true" />
                </div>
              </div>
              <div class="row">
                <div class="col" data-tooltip="Page type can be changed in the advanced tab">
                  <base-input label="Page type" :modelValue="page.template.name" read-only="true" disabled />
                </div>
                <div class="col d-flex justify-content-between">
                  <base-select
                    class="col pl-0"
                    label="Status"
                    v-model="page.status"
                    :disabled="hasPermission('misc.sites/limited-to-own-pages')"
                    :custom-options="page.available_statuses"
                  />
                  <base-date
                    v-if="page.status === 'scheduled'"
                    label="Scheduled at"
                    v-model="page.scheduled_at"
                    :config="datePickerConfig"
                    class="col pr-0"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-multiselect
                    :multiple="true"
                    field="authors"
                    label="Authors"
                    :path="`hercules/sites/authors?site_id=${page.site_id}&orderBy=name&ascending=1`"
                    :value="page.authors"
                    @sync="syncAuthors"
                  />
                </div>
                <div class="col">
                  <base-select
                    label="Reviewer"
                    v-model="page.reviewer_id"
                    :path="`hercules/sites/authors?site_id=${page.site_id}&orderBy=name&ascending=1`"
                    :clearable="true"
                    option-label-key="name"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input
                    label="Vanity label"
                    v-model="page.vanity_label"
                    help-text="Overrides the breadcrumbs title"
                  />
                </div>
                <div class="col-6 category-list">
                  <base-multiselect
                    ref="categorySelect"
                    :multiple="true"
                    field="categories"
                    label="Categories"
                    :path="`hercules/sites/categories`"
                    :parameters="{
                      market_id: page.market_id,
                      site_id: page.site_id,
                      page_type_id: page.template_id,
                      orderBy: 'name',
                      ascending: 1,
                    }"
                    :value="page.categories"
                    :noOptionsPlaceholder="'categories'"
                    :noOptionsLink="`/site-sites/${page.site_id}/categories?marketId=${page.market_id}&pageTypeId=${page.template_id}`"
                    :showMissingOptionLinkOnSearch="true"
                    :showExtraOptionProperty="true"
                    :extraOptionPropertyIsActive="isPrimary"
                    extraOptionProperty="primary"
                    @sync="
                      (field, value, trackBy, onChange) => handleCategoryChange(page, field, value, onChange)
                    "
                  >
                    <template v-slot:missingOptionLink="slotProps">
                      <div
                        v-if="missingOption"
                        class="text-muted pt-4 px-4 pb-2"
                        :class="{ show: slotProps.showDropdown }"
                      >
                        <span
                          >No categories available when searching for: {{ slotProps.search }}
                          , click
                          <a
                            :href="`/site-sites/${page.site_id}/categories?marketId=${page.market_id}&pageTypeId=${page.template_id}`"
                            target="blank"
                          >
                            here
                          </a>
                          to add one
                        </span>
                      </div>
                    </template>
                    <template v-slot:extraOptionBadge="slotProps">
                      <span
                        v-if="
                          slotProps.option && page.categories.find((item) => item.id == slotProps.option.id)
                        "
                        :id="slotProps.option['id'] + 'badge'"
                        class="badge badge-opacity"
                        v-bind:class="[
                          isPrimary(slotProps.option['id']) ? 'bg-azure' : 'bg-black-25 opacity-4',
                        ]"
                        @click.stop.prevent="setPrimary(slotProps.option['id'])"
                        @mouseenter="onMouseEnter(slotProps.index, isPrimary(slotProps.option['id']))"
                        @mouseleave="onMouseLeave"
                      >
                        {{
                          hoveredIndex === slotProps.index && isPrimary(slotProps.option['id'])
                            ? 'Remove Primary'
                            : 'Set Primary'
                        }}
                      </span>
                    </template>
                  </base-multiselect>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  page.categories.length > 0 &&
                  page.categories.length == 1 &&
                  page.categories[0].sub_categories &&
                  page.categories[0].sub_categories.length > 0
                "
              >
                <div class="col-6"></div>
                <div class="col-6">
                  <base-select
                    label="Sub Category"
                    v-model="page.sub_category"
                    :customOptions="page.categories[0]?.sub_categories || []"
                    :clearable="true"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <base-select
                    label="Style"
                    placeholder="Choose style"
                    v-model="page.style_id"
                    :path="`hercules/sites/page-styles`"
                    :parameters="{
                      site_id: page.site_id,
                      market_id: page.market_id,
                      page_type_id: page.template_id,
                    }"
                    :clearable="true"
                    option-label-key="name"
                    noOptionsPlaceholder="style"
                    :noOptionsLink="`/site-templates/${page.template_id}/page-styles`"
                  />
                </div>
                <div class="col-6">
                  <model-select-modal
                    v-if="
                      ['page', 'article'].includes(page.template.type) === false &&
                      page.template.multiple_relation_type == false
                    "
                    :parameters="{
                      market_id: page.market_id,
                      site_id: page.site_id,
                      affiliate_id: page.site.affiliate_id,
                    }"
                    :model="page.template.type"
                    :value="page.relation_id"
                    :value-label="page.relation_label"
                    ref="modelSelect"
                    :customLabel="`${page.relation_type} Relation `"
                    @valuePicked="relationSelected"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6" v-if="page.template.enable_manual_published_date === 1">
                  <base-date
                    label="Manual published date"
                    v-model="page.manual_published_date"
                    :config="getDatePickerConfig()"
                  />
                </div>
                <div class="col-6" v-if="page.template.enable_manual_updated_date === 1">
                  <base-date
                    label="Manual updated date"
                    v-model="page.manual_updated_date"
                    :config="getDatePickerConfig()"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  ['page', 'article'].includes(page.template.type) === false &&
                  page.template.multiple_relation_type
                "
              >
                <div class="col border-bottom pb-4">
                  <model-select-modal
                    :parameters="{
                      market_id: page.market_id,
                      site_id: page.site_id,
                      affiliate_id: page.site.affiliate_id,
                    }"
                    :model="page.template.type"
                    :multipleSelect="true"
                    :value="page.relation_id"
                    :value-label="page.relation_label"
                    ref="modelSelect"
                    :customLabel="`${page.relation_type} + Relation `"
                    @valuePicked="relationSelected"
                  />
                </div>
              </div>
              <div class="row" v-if="page.template.has_description">
                <div class="col">
                  <base-rich-text
                    label="Short description"
                    :modelValue="page.description"
                    height="120"
                    :site-id="page.site_id"
                    :market-id="page.market_id"
                    @valueChanged="(value) => (page.description = value)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-checkbox label="Speakable Schema" v-model="page.speakable_schema" />
                </div>
              </div>
              <div class="row" v-if="page.template.has_featured_image">
                <div class="col">
                  <media v-model="page.featured_image_id" label="Featured Image" :siteSpecific="true" />
                </div>
              </div>
              <div v-if="page.template.country_filterable" class="row">
                <div class="col">
                  <base-multiselect
                    :multiple="true"
                    field="countries"
                    label="Countries"
                    path="hercules/globals/countries"
                    :value="page.countries"
                    @sync="(field, value) => (page.countries = value ? value : null)"
                    helpText="Field can be used to associate this page to one or more countries"
                  />
                </div>
              </div>
            </div>
            <div v-if="page.extra_fields && page.extra_fields.length > 0">
              <h3 class="d-inline-block mt-4 mb-3">Extra fields</h3>
              <span
                class="ml-2 text-muted clickable"
                @click="() => $router.push(`/site-templates/${page.template_id}/extra-fields`)"
                >manage</span
              >
            </div>
            <dynamic-field
              v-for="(field, key) in page.extra_fields"
              v-bind:key="key"
              :field="field"
              :siteSpecific="true"
              :siteId="page.site_id"
              :affiliateId="page.site.affiliate_id"
              :marketId="page.market_id"
            />
          </div>
          <information-modal ref="informationModal" :id="confluenceId" />
        </div>
      </div>
    </div>
    <user-feedback :page="page"></user-feedback>
    <authors-contributor v-model="contributors" :authors="page.authors" label="Author Contributors" />
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import BaseCodeText from '@atoms/fields/base-code-text.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import DynamicField from '@/components/fields/dynamic-field.vue'
import BaseRichText from '@atoms/fields/base-rich-text.vue'
import Media from '@molecules/fields/media.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import ModelSelectModal from '@molecules/fields/model-select/base-model-select.vue'
import confluenceIds from '@constants/confluence-ids.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import UserFeedback from '@pages/cms/pages/edit/user-feedback.vue'
import AuthorsContributor from '@pages/cms/pages/edit/authors-contributor.vue'

export default {
  data() {
    return {
      previewChange: null,
      mounted: false,
      confluenceId: 0,
      datePickerConfig: {
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true,
        enableTime: true,
      },
      categories: null,
      websiteTrueVotes: 0,
      websiteFalseVotes: 0,
      hoveredIndex: null,
      contributors: [],
    }
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseMultiselect,
    BaseRichText,
    BaseDate,
    Media,
    BaseCheckbox,
    BaseCodeText,
    DynamicField,
    ModelSelectModal,
    PageStatusBadge,
    InformationModal,
    UserFeedback,
    AuthorsContributor,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    contributors: {
      handler: function (val) {
        this.page.authors.forEach((author) => {
          let foundValue = val.find((item) => item.id === author.id)
          if (foundValue) {
            author.contribution_value_id = foundValue.contribution_value_id
            author.contribution_value = foundValue.contribution_value
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.confluenceId = confluenceIds.pages.general.id
  },
  mounted() {
    // to prevent hreflang group prediction on mount
    if (this.page.relation_id === null) {
      this.mounted = true
    }
  },
  computed: {
    missingOption() {
      return this.$refs['categorySelect'].getOptionItems.length === 0 && !this.$refs['categorySelect'].loading
    },
  },
  methods: {
    //Here we listen for category change, and when changed we set the sub category to null.
    handleCategoryChange(page, field, value, change) {
      page.categories = value
      if (change) {
        page.sub_category = null
      }
    },
    syncAuthors(field, value) {
      this.page.authors = value ? value : null

      // Ensure we keep authors with contributions even if they are removed from authors list
      const existingContributors = this.contributors.filter((contributor) =>
        this.page.authors.some((author) => author.id === contributor.id)
      )

      this.contributors = existingContributors
      this.contributionAuthors = [...new Set([...this.page.authors, ...this.contributors])]
    },
    onMouseEnter(index, item) {
      if (item) {
        this.hoveredIndex = index
      }
    },
    onMouseLeave() {
      this.hoveredIndex = null
    },
    getDatePickerConfig() {
      return this.datePickerConfig
    },
    openModalInformation() {
      this.$refs.informationModal?.show()
    },
    viewActivityLogs() {
      let routeData = this.$router.resolve({
        path: `/activity-logs?`,
        query: {
          subject_name: this.page.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    relationSelected(value, label) {
      this.page.relation_label = label
      // do not have to predict hreflang group on mount
      if (!this.mounted) {
        this.mounted = true
        return
      }
      if (value === null) {
        this.page.relation_id = null
        this.page.page_hreflang_group_id = null
        return
      }
      // used for multi-selects (tournaments)
      if (Array.isArray(value)) {
        this.page.relation_id = value.join(',')
      } else {
        this.page.relation_id = value
      }
    },
    setPrimary(id) {
      if (!this.disabled) {
        const currValue = this.isPrimary(id)
        this.page.categories.filter((item) => (item.primary = false))

        if (!currValue) {
          this.page.categories.filter((item) => {
            if (item['id'] === id) {
              item.primary = true
            }
          })
        }
        this.$forceUpdate()
      }
    },
    isPrimary(id) {
      return this.page.categories && this.page.categories.find((item) => item['id'] == id)?.primary
    },
  },
}
</script>

<style>
.category-list ul {
  max-height: 192px;
  overflow-x: auto;
}
</style>
