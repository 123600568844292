<template>
  <div :class="wrapperClass" :hidden="type === 'hidden'">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>

    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>
    <!-- No, noOptionsPlaceholder for this select, use the old Base-Select -->
    <div class="dropdown">
      <span @click="clearSelection" v-if="canBeCleared">
        <i class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0" style="top: \5px" />
      </span>
      <div class="multiselect__arrow" :class="{ active: showDropdown }" v-else></div>
      <input
        type="text"
        :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
        @click="dropdownClicked"
        class="form-control"
        v-debounce:200ms="valueChanged"
        v-model="activeOptionLabel"
        :placeholder="placeholderLabel ? placeholderLabel : placeholder"
        ref="dropdownInput"
        @blur="hideDropdown"
        @keydown.up.exact.prevent="focusPrevious(true)"
        @keydown.down.exact.prevent="focusNext(true)"
        @keypress.enter.exact.prevent="selectItem"
        @keyup.esc.exact.prevent="$parent.formType === 'edit' && hideDropdown()"
      />

      <span v-if="canBeCleared" @click="clearInput">
        <i
          class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0"
          style="top: 5px"
        ></i>
      </span>

      <div class="dropdown-menu list-container" ref="dropdown-list" v-if="showDropdown">
        <li v-if="loading && !customOptions">Loading ...</li>
        <div @click="clearSelection" v-if="showAllOption">All</div>

        <!-- customOptions need id's now -->
        <!-- Virtual list usage -->
        <virtual-list
          class="dropdown-item"
          :style="[
            staticStyles,
            dropdownHeight !== null ? { 'max-height': dropdownHeight + 'px', 'overflow-y': 'auto' } : {},
          ]"
          :data-sources="optionItems"
          :data-key="trackBy"
          :data-component="activeOption"
          :extra-props="{
            dataFromParent: data,
            trackBy,
            pointer,
            optionLabelKey,
            allowNullOption,
            removeActiveText,
            extraOptionLabelKey,
          }"
          :wrap-class="'wrapper-items'"
          :footer-class="'footer-items'"
          :lengthOfOptions="lengthOfOptions"
          :lengthOfItems="optionItems.length"
        />
        <span v-if="optionItems.length === 0 && !loading">No matching records</span>
        <span v-else-if="loading">Loading...</span>
      </div>
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import GeneralService from '../../services/GeneralService'
import activeOption from './vue-scroll-component/scroll-item.vue'

export default {
  name: 'base-select-virtual',
  props: {
    removeActiveText: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      default: () => 'form-group position-relative',
    },
    capitalizeLabel: {
      default: true,
      type: Boolean,
    },
    clearable: {
      default: () => false,
    },
    clearableIfOneOption: {
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => {},
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select an option',
    },
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    modelValue: {
      default: () => null,
    },
    customOptions: {
      default: () => null,
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    selectFirstAfterFetch: {
      default: () => false,
      type: Boolean,
    },
    selectFirstIfOneResult: {
      default: () => false,
      type: Boolean,
    },
    loadOnMount: {
      default: () => true,
      type: Boolean,
    },
    path: {
      default: () => null,
      type: String,
    },
    emitOption: {
      type: String,
      default: () => null,
    },
    noOptionsLink: {
      type: String,
    },
    trackBy: {
      type: String,
      default: () => 'id',
    },
    showAllOption: {
      type: Boolean,
      default: () => false,
    },
    notifiedByField: {
      type: Object,
      default: () => null,
    },
    allowNullOption: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: 'list-one',
    },
    extraOption: {
      type: String,
      default: () => null,
    },
    searchBy: {
      type: String,
      default: () => null,
    },
    extraOptionLabelKey: {
      type: String,
      default: () => null,
    },
    pageCount: {
      type: Boolean,
      default: () => false,
    },
    enableDynamicHeight: {
      type: Boolean,
      default: () => false,
    },
    enableTooltip: {
      type: Boolean,
      default: () => false,
    },
    advancedFilterParams: {
      type: Boolean,
      default: () => false,
    },
    countryFlag: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      hoveredElement: null,
      data: this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue,
      options: [],
      originalOptions: [],
      loading: true,
      optionItems: [],
      activeOptionLabel: '',
      placeholderLabel: '',
      pointer: 0,
      focusedIndex: 0,
      activeOption: markRaw(activeOption),
      search: '',
      lastDropdownClicked: 0,
      dynamicHeight: null,
      showDropdown: false,
      staticStyles: {
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 9998,
      },
    }
  },
  computed: {
    dropdownHeight() {
      // 52 is the height of the all option
      return this.dynamicHeight > 0 && this.enableDynamicHeight ? this.dynamicHeight - 52 : null
    },
    canBeCleared() {
      if (!this.clearable || !this.data) return false
      if (this.options.length === 1) return this.clearableIfOneOption
      return true
    },
    lengthOfOptions() {
      return this.optionItems.length <= 7 ? this.optionItems.length : 7
    },
  },
  provide() {
    return {
      onChangeItem: this.onChange,
    }
  },
  created() {
    if (this.customOptions) {
      this.options = [...this.customOptions]
      this.optionItems = [...this.customOptions]
      this.updateActiveOptionLabel()
      this.loading = false
    } else if (
      (this.path && this.loadOnMount) ||
      this.data ||
      (this.notifiedByField &&
        this.$parent &&
        this.$parent.$parent &&
        this.$parent.$parent.object &&
        this.$parent.$parent.object[this.notifiedByField.field])
    ) {
      this.getOptions()
    }

    if (this.modelValue instanceof Object) {
      this.$emit(
        'update:modelValue',
        this.data,
        this.emitOption
          ? this.options.filter((item) => {
              if (item[this.trackBy] === this.modelValue[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )
    }
  },
  watch: {
    modelValue() {
      this.data = this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue
      this.updateActiveOptionLabel()
    },
    customOptions(newVal) {
      if (newVal) {
        this.options = [...newVal]
        this.optionItems = [...newVal]
        this.updateActiveOptionLabel()
        this.loading = false
      }
    },
    options() {
      this.optionItems = this.options
      if (this.optionItems.length > 0) {
        if (this.pointer !== (this.optionItems[0] && this.optionItems[0][this.trackBy])) {
          this.focusItem()
        }
      }
    },
    activeOptionLabel() {
      if (!this.searchBy && this.options) {
        const searchLower = this.activeOptionLabel ? this.activeOptionLabel.toLowerCase() : ''

        this.optionItems = this.options
          .filter((item) => {
            const optionLabel =
              item instanceof Object && this.optionLabelKey in item
                ? item[this.optionLabelKey].toString().toLowerCase()
                : item.toString().toLowerCase()
            return optionLabel.includes(searchLower)
          })
          .sort((a, b) => {
            const getOptionLabel = (item) =>
              item instanceof Object && this.optionLabelKey in item
                ? item[this.optionLabelKey].toString().toLowerCase()
                : item.toString().toLowerCase()

            const optionA = getOptionLabel(a)
            const optionB = getOptionLabel(b)

            const startsWithA = optionA.startsWith(searchLower)
            const startsWithB = optionB.startsWith(searchLower)

            if (startsWithA && !startsWithB) {
              return -1
            } else if (!startsWithA && startsWithB) {
              return 1
            } else {
              return optionA.localeCompare(optionB)
            }
          })
        this.activeOptionLabel = this.prettifyLabels(this.activeOptionLabel)
      } else {
        this.optionItems = this.options ? [...this.options] : []
      }
    },
  },
  methods: {
    valueChanged(value) {
      if (this.searchBy) {
        this.search = value || ''
        if (this.search === '') {
          if (this.customOptions) {
            this.options = [...this.customOptions]
            this.optionItems = [...this.customOptions]
          } else {
            this.options = [...this.originalOptions]
          }
        } else {
          if (this.customOptions) {
            const searchLower = this.search.toLowerCase()
            this.optionItems = this.customOptions.filter((item) => {
              const optionLabel = item[this.optionLabelKey]?.toString().toLowerCase() || ''
              return optionLabel.includes(searchLower)
            })
          } else {
            this.getOptionsBySearch()
          }
        }
      }
    },
    updateActiveOptionLabel() {
      if (this.data !== null && this.data !== undefined && this.options.length > 0) {
        let option = this.options.filter((item) =>
          item instanceof Object
            ? item[this.trackBy] === (!isNaN(this.data) ? parseInt(this.data) : this.data)
            : item === this.data
        )[0]

        let optionLabel =
          option instanceof Object
            ? this.extraOptionLabelKey
              ? option[this.extraOptionLabelKey] + ' > ' + option[this.optionLabelKey]
              : option[this.optionLabelKey]
            : option

        this.activeOptionLabel = this.capitalizeLabel ? this.capitalize(optionLabel) : optionLabel
      } else {
        this.activeOptionLabel = ''
        this.placeholderLabel = ''
      }
    },
    dropdownClicked() {
      const now = Date.now()
      if (now - this.lastDropdownClicked < 250) return
      this.lastDropdownClicked = now

      if (this.path && !this.loadOnMount && this.options.length === 0) {
        this.getOptions()
      }

      this.placeholderLabel = this.activeOptionLabel
      this.activeOptionLabel = ''

      this.$refs.dropdownInput?.focus()
      this.showDropdown = true
      this.getDropdownHeight()
      if (!this.pointer) {
        this.focusItem()
      }
    },
    getDropdownHeight() {
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.calculateDropdownHeight()
        })
      }
    },
    calculateDropdownHeight() {
      const dropdownListRect = this.$refs['dropdown-list'].getBoundingClientRect()
      const tableRect = document.querySelector('.card-body')
        ? document.querySelector('.card-body').getBoundingClientRect()
        : 0

      const spaceBelowViewport = window.innerHeight - dropdownListRect.top
      const spaceBelowTable = tableRect.bottom - dropdownListRect.top
      const maxDropdownHeight = Math.min(spaceBelowViewport, spaceBelowTable)

      this.dynamicHeight = maxDropdownHeight > 0 ? maxDropdownHeight : 0
    },
    onChange(option) {
      if ((option instanceof Object ? option[this.trackBy] : option) === this.data && this.allowNullOption) {
        this.data = null
        this.activeOptionLabel = ''
      } else {
        this.data = option instanceof Object ? option[this.trackBy] : option
      }

      this.$emit(
        'update:modelValue',
        this.data,
        this.emitOption
          ? this.options.filter((item) => {
              if (item[this.trackBy] === option[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )

      if (this.searchBy) {
        this.search = ''
        if (!this.customOptions) {
          if (!this.originalOptions.length) {
            this.getOptions()
          }
        } else {
          this.options = [...this.customOptions]
          this.optionItems = [...this.customOptions]
        }
      }

      this.$refs.dropdownInput.blur()
    },
    buildRequestData() {
      let data = {}
      if (this.notifiedByField && this.$parent.$parent.object) {
        const key = this.notifiedByField.request_field_name_parameter
          ? this.notifiedByField.request_field_name
          : this.notifiedByField.field
        const value = this.notifiedByField.request_field_name_parameter
          ? this.$parent.$parent.object[this.notifiedByField.request_field_name_parameter]
          : this.$parent.$parent.object[this.notifiedByField.field]

        data[key] = value
      }

      if (this.advancedFilterParams) {
        // Destructure to remove 'exact_match' and spread the rest properties into 'tempParams'
        // eslint-disable-next-line
        const { exact_match, ...tempParams } =
          this.$store.state['model-select-items-selector'].advancedFilters
        // Create 'sanitizedParams' from 'tempParams'
        const sanitizedParams = { ...tempParams }

        // Conditionally rename 'tournament__gender' to 'gender' if 'gender' is not already set
        if (tempParams.tournament__gender && !tempParams.gender) {
          sanitizedParams.gender = tempParams.tournament__gender
          delete sanitizedParams.tournament__gender
        }

        data = {
          ...data,
          ...sanitizedParams,
        }
      }

      data = {
        ...data,
        ...(this.parameters ? this.parameters : {}),
      }
      return data
    },
    getOptions(selectFirst = false) {
      let data = this.buildRequestData()
      return new Promise((resolve) => {
        GeneralService.fetchItems(this.path, data).then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            return
          }

          this.setOptions(response.data.result, true)

          if (
            this.searchBy &&
            this.search === '' &&
            this.data &&
            this.options.filter((option) => option[this.trackBy] === this.data).length === 0
          ) {
            this.setActiveOption()
          }

          this.$nextTick(() => {
            if (
              selectFirst ||
              this.selectFirstAfterFetch ||
              (this.selectFirstIfOneResult && this.options.length === 1)
            ) {
              this.selectFirstOption()
            }
          })

          if (this.loading) {
            this.updateActiveOptionLabel()
          }
          this.loading = false
          resolve(this.options)
        })
      })
    },
    getOptionsBySearch() {
      let data = this.buildRequestData()

      data[this.searchBy] = this.search

      GeneralService.fetchItems(this.path, data).then((response) => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
          return
        }
        this.options = response.data.result.map((item) => {
          const option = {
            [this.trackBy]: item[this.trackBy],
            [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
            ...(this.pageCount ? { pages_count: item.pages_count } : {}),
            ...(this.extraOption ? { [this.extraOption]: item[this.extraOption] } : {}),
            ...(this.countryFlag ? { countryFlag: this.resolve(this.countryFlag, item) } : {}),
            ...(this.extraOptionLabelKey
              ? { [this.extraOptionLabelKey]: this.resolve(this.extraOptionLabelKey, item) }
              : {}),
          }
          return option
        })
      })
    },
    setActiveOption() {
      if (this.trackBy === 'id') {
        GeneralService.fetchItem(this.path.split('?')[0], this.data, {}).then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            return
          }

          this.setOptions(response.data.result)
          this.updateActiveOptionLabel()
        })
        return
      }

      const data = {}
      data[this.trackBy] = this.data
      GeneralService.fetchItems(this.path.split('?')[0], data).then((response) => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
          return
        }
        this.setOptions(response.data.result)
        this.updateActiveOptionLabel()
      })
    },
    setOptions(data, setOriginalOptions = false) {
      if (!this.emitOption) {
        this.options = data
          .filter((item) => {
            return !!this.resolve(this.optionLabelKey, item)
          })
          .map((item) => {
            const option = {
              [this.trackBy]: item[this.trackBy],
              [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
              ...(this.pageCount ? { pages_count: item.pages_count } : {}),
              ...(this.countryFlag ? { countryFlag: this.resolve(this.countryFlag, item) } : {}),
              ...(this.extraOption ? { [this.extraOption]: item[this.extraOption] } : {}),
              ...(this.extraOptionLabelKey
                ? { [this.extraOptionLabelKey]: this.resolve(this.extraOptionLabelKey, item) }
                : {}),
            }
            return option
          })
      } else {
        this.options = data.map((item) => ({
          [this.trackBy]: item[this.trackBy],
          [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
          [this.emitOption]: item[this.emitOption],
          ...(this.pageCount ? { pages_count: item.pages_count } : {}),
          ...(this.countryFlag ? { countryFlag: this.resolve(this.countryFlag, item) } : {}),
          ...(this.extraOptionLabelKey
            ? { [this.extraOptionLabelKey]: this.resolve(this.extraOptionLabelKey, item) }
            : {}),
        }))
      }
      if (setOriginalOptions) {
        this.originalOptions = [...this.options]
      }
    },
    resolve(path, obj) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    selectFirstOption() {
      if (this.options && this.options.length) {
        this.$emit(
          'update:modelValue',
          this.options[0][this.trackBy],
          this.emitOption ? this.options[0][this.emitOption] : ''
        )
      } else {
        this.$emit('update:modelValue', null)
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = (this.focusedIndex + 1) % this.optionItems.length
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = (this.focusedIndex - 1 + this.optionItems.length) % this.optionItems.length
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem() {
      if (this.optionItems.length > 0) {
        this.pointer = this.optionItems[this.focusedIndex]?.id || null
      } else {
        this.pointer = null
      }
    },
    selectItem() {
      this.onChange(this.optionItems[this.focusedIndex])
    },
    changePointer(element, index) {
      this.hoveredElement = this.enableTooltip ? element : null
      this.focusedIndex = index
      this.focusItem()
    },
    clearSelection() {
      this.$emit('update:modelValue', null)
      this.$emit('isValueClearedListener')
    },
    hideDropdown(e) {
      setTimeout(() => {
        this.hoveredElement = null
        this.$refs.dropdownInput?.blur()
        this.focusedIndex = 0
        this.valueChanged('')
        this.focusItem()
        this.showDropdown = false
      }, 300)
    },
    prettifyLabels(label) {
      return this.capitalizeLabel ? label && label[0]?.toUpperCase() + label.slice(1) : label
    },
  },
}
</script>
<style lang="scss">
.wrapper-items {
  background: white !important;
  color: #6e6e84 !important;
}

.list-container {
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 !important;
  margin: 5rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  .dropdown-item {
    padding: 0 !important;
    bottom: 0;
  }
}
</style>
<style scoped>
.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.list-container::-webkit-scrollbar {
  width: 0;
}

.dropdown input {
  padding: 0 14px;
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.dropdown > input.form-control {
  background-image: unset !important;
}

.dropdown > input.form-control::placeholder,
.dropdown > input.form-control:focus {
  color: #999 !important;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.absolute-clear-button {
  position: absolute;
  top: -25px;
  right: 4px;
}

.no-hover {
  color: #17182f !important;
  font-weight: 500 !important;
  /* font-size: 12px !important; */
  background: white;
  text-align: center;
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
